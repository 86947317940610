import React from 'react';
import { 
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import datinfilogo2 from '../../images/logo_Side.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(241 240 240)',
    position: 'static',
    display: 'flex',
    // zIndex : 5000,
  },
  customButton: {
    cursor: "pointer",
    transition: "transform 200ms ease-in-out",
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: 'black',
      backgroundColor: "#03a1a1e0",
      transform: 'scale(0.9)',
    },
  },

  menuButton: {
    color: '#3c3c3c',
    // marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    objectFit: 'contain',
    [theme.breakpoints.up('sm')]: {
      // display: 'none',
    },
  },
  navItems: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  'navbar-false': {
    display: 'none',
  },
  'navbar-true': {
    flex: 1,
    textAlign: 'center',
    width: 240,
    marginTop: '55px',
    height: '50%',
    marginRight: '15px',
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  'navbarContainer-true': {
    background: 'rgba(1, 22, 61, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    zIndex: 2000,
    position: 'fixed',
    height: '100%',
  },
}));

const navItems = ['Home', 'About', 'Services', 'Blog', 'Contact'];

const Nav = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const drawer = () => {
  //   return(
  //     <Box className={classes[`navbar-${mobileOpen}`]} onClick={handleDrawerToggle}>
  //       <Typography variant="body1" style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
  //         <CloseIcon fontSize='small' style={{ color: 'white' }} />
  //       </Typography>
  //       <List style={{
  //         backgroundColor: 'white',
  //         overflow: 'auto', borderRadius: '10px',
  //         textAlign: 'center',
  //       }}>
  //         {navItems.map((item, key) => (
  //           <Link to={item} smooth={true} duration={400} key={key}>
  //             <ListItem key={item} style={{ height: '40px' }} >
  //               <ListItem button onClick={handleDrawerToggle} style={{ textAlign: 'center', }}>
  //                 <ListItemText variant={'body1'} primary={item} />
  //               </ListItem>
  //             </ListItem>
  //           </Link>
  //         ))}
  //       </List>
  //     </Box>
  //   )
  // };


  return (
    <Box className={classes.rootbox} style={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar component="nav" className={classes.root}>
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            className={classes.title}
          >
            <img src={datinfilogo2} width={150} height={45} alt='datinfilogo1'></img>
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={()=>{handleDrawerToggle()}}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Box className={classes.navItems}>
            {navItems.map((item, key) => (
              <Link className={classes.appbar_button} to={item} smooth={true} duration={400} offset={-1} key={key}>
                <Button className={classes.customButton}>
                  <Typography variant='button' style={{ textTransform: 'none' }}>{item}</Typography>
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box className={classes[`navbarContainer-${mobileOpen}`]}>
        <Box className={classes[`navbar-${mobileOpen}`]} onClick={()=>{handleDrawerToggle()}}>
          <Typography variant="body1" style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
            <CloseIcon fontSize='small' style={{ color: 'white' }} />
          </Typography>
          <List style={{
            backgroundColor: 'white',
            overflow: 'auto', borderRadius: '10px',
            textAlign: 'center',
          }}>
            {navItems.map((item, key) => (
              <Link to={item} smooth={true} duration={400} key={key}>
                <ListItem key={item} style={{ height: '40px' }} >
                  <ListItem button onClick={()=>{handleDrawerToggle()}} style={{ textAlign: 'center', }}>
                    <ListItemText variant={'body1'} primary={item} />
                  </ListItem>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
}

export default Nav;
