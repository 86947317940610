import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
  aboutbox_text: {
    flex: 1,
    justifyContent: 'center',
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      paddingLeft: '0px',
    }

  },
  text_tittle: {
  }

}))

const AboutContent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.aboutbox_text}>
      <Typography style={{ marginBottom: '30px', marginTop: '30px',textAlign:'justify' }} variant='h3' className={classes.text_tittle}>
        Who we are ...
      </Typography>
      <Typography style={{ marginBottom: '30px',textAlign:'justify' }} variant='body1' /*style={{marginTop:'20px'}}*/ >
        Datinfi was founded in Coimbatore, Tamilnadu, India, in 2016 by analysts and machine learning experts to bring the power of transformative technology to core business operations of SMEs.
      </Typography>
      <Typography style={{ marginBottom: '30px',textAlign:'justify' }} variant='body1'>
        Datinfi Private Limited is a dynamic and innovative company that specializes in providing advanced data analytics solutions to businesses across various industries. With a strong focus on delivering actionable insights and driving data-driven decision-making, we empower our clients to unlock the full potential of their data.
      </Typography>
      <Typography style={{ marginBottom: '30px',textAlign:'justify' }} variant='body1'>
        At Datinfi, we pride ourselves on our team of highly skilled professionals who are passionate about data and technology. Our talented data scientists, engineers, and analysts work collaboratively to develop cutting-edge solutions that address our clients' unique challenges and drive business growth.
      </Typography>
      <Typography style={{ marginBottom: '30px',textAlign:'justify' }} variant='body1'>
        With a client-centric approach, we strive to understand the specific needs and goals of each organization we partner with. By leveraging state-of-the-art technologies and industry best practices, we deliver tailored solutions that provide measurable results and tangible value.
      </Typography>

    </Box>
  )
}

export default AboutContent