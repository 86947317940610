import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Box, Typography, IconButton } from '@material-ui/core'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'
import Avatar from '@material-ui/core/Avatar';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import directorPic from  '../../../images/Director_pic_whiteblack.jpg'
import Seniordevpic from  '../../../images/Senior_devops_pic_Black&white1.jpg'
import TechLeadPic from  '../../../images/TechLead_pic_B&w.jpg'

const useStyles = makeStyles((theme) => ({
  CarouselContainer: {
    display: 'flex',
    height: "100%",
    marginLeft: '100px',
    marginRight: '100px',
    marginTop: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 1000px)": {
      flexDirection: 'column',
      marginLeft: '5px',
      marginRight: '5px',
    }

  },
  carouselprev: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '5px',
    transform: 'scale(0.7)',
    "@media (max-width: 1000px)": {
      display: 'none',
    }
  },
  carouselActive: {
    width: '250px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '5px',
    transform: 'scale(1.2)',
    "@media (width: 1024px)": {
      marginLeft: '40px',
      marginRight: "40px",
    },
    "@media (max-width: 1000px)": {
      paddingTop: '30px',
      marginRight: '20px',
      marginLeft: '20px',
      transform: 'scale(1)',
    },
    "@media (max-width: 768px)": {
    }
  },
  carouselnext: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '5px',
    transform: 'scale(0.7)',
    "@media (max-width: 1000px)": {
      display: 'none',
    }
  },
  avatarsize: {
    width: '144px',
    height: '144px',
  },

  CarouselHeadText: {
    textAlign: 'center',
    marginTop: '38px',
    marginLeft: '20px',
    marginRight: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '36px',
    "@media (max-width: 768px)": {
      marginBottom: '10px'
    }
  },

  navIcon: {
    opacity: 0.2,
    '&:hover': {
      opacity: 0.5,
    },

  },
  prevButton: {
    flex: 0.1,
    display: 'flex',
    marginLeft: '20px',
    alignItems: 'center',
    "@media (max-width: 1000px)": {
      display: 'none',
    }

  },
  nextButton: {
    display: 'flex',
    marginLeft: '20px',
    alignItems: 'center',

  },



}))



function CarouselContainer(props) {

  const classes = useStyles();
  const [prevIndex, SetprevIndex] = useState(3)
  const [nextIndex, SetnextIndex] = useState(1)
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);


  const handleNext = (next) => {
    console.log(`We left slide ${activeSlide}, and are now at slide ${next}`);
    setActiveSlide(next)
    const nextitem = next === 3 ? 0 : next + 1;
    SetnextIndex(nextitem)
    const previtem = next === 0 ? 3 : next - 1;
    SetprevIndex(previtem)
  };

  const handlenavigationNext = () => {
    if (!isNextButtonDisabled) {
      const nextSlide = (activeSlide + 1) % items.length;
      setActiveSlide(nextSlide);
      const nextitem = nextSlide === 3 ? 0 : nextSlide + 1;
      SetnextIndex(nextitem)
      const previtem = nextSlide === 0 ? 3 : nextSlide - 1;
      SetprevIndex(previtem)
      setIsNextButtonDisabled(true);
    }
  };

  const handlenavigationprev = () => {
    if (!isPrevButtonDisabled) {
      const prevSlide = activeSlide === 0 ? items.length - 1 : activeSlide - 1;
      setActiveSlide(prevSlide);
      const nextitem = prevSlide === 3 ? 0 : prevSlide + 1;
      SetnextIndex(nextitem)
      const previtem = prevSlide === 0 ? 3 : prevSlide - 1;
      SetprevIndex(previtem)
      setIsPrevButtonDisabled(true);



    }


  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsNextButtonDisabled(false);
      setIsPrevButtonDisabled(false);
    }, 800);

    return () => clearTimeout(timeout);
  }, [activeSlide]);

  const handleMouseEnter = () => {
    setAutoplay(false);
  };

  const handleMouseLeave = () => {
    setAutoplay(true);
  };



  var items = [
    {
      avatar: <Avatar  src={directorPic} className={classes.avatarsize} />,
      name: "Vishnuvardhan T",
      designation: "Director"
    },
    {
      avatar: <Avatar style={{ backgroundColor: 'blue' }} className={classes.avatarsize}></Avatar>,
      name: "Kirthiga N",
      designation: "HR Department"
    },
    {
      avatar: <Avatar src={Seniordevpic} className={classes.avatarsize}></Avatar>,
      name: "Nirmal Kumar S",
      designation: "Senior Devops Engineer"
    },
    {
      avatar: <Avatar src={TechLeadPic} className={classes.avatarsize}></Avatar>,
      name: "Ananthkumar K",
      designation: "Technical Lead"
    }
  ]



  return (

    <Box>
      <Box className={classes.CarouselHeadText}>
        <Typography variant='h2'>Discover Our Creative Minds</Typography>
      </Box>

      <Box className={classes.CarouselContainer}>


        <Box style={{ display: '' }} className={`${classes.navIcon} ${classes.prevButton}`}>
          <IconButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handlenavigationprev} disabled={isPrevButtonDisabled}>
            <NavigateBeforeIcon fontSize='large' />
          </IconButton>
        </Box>

        <Box style={{ display: 'flex', flex: 0.8, justifyContent: 'center', alignItems: 'center' }}>
          <Carousel
            duration={700}
            animation='Slide' autoPlay={false} navButtonsAlwaysInvisible>
            <Box className={classes.carouselprev}>
              <Box style={{ marginBottom: '20px' }}>
                {items[prevIndex].avatar}
              </Box>
              <Box>
                <Typography variant='body1'>{items[prevIndex].name}</Typography>
                <Typography variant='body2'>{items[prevIndex].designation}</Typography>
              </Box>
            </Box>
          </Carousel>


          <Carousel
            animation='slide'
            active={activeSlide}
            duration={500}
            interval={3000}
            autoPlay={false}
            next={(handleNext)}
            onChange={handleNext}
            indicatorContainerProps={
              { style: { marginTop: '5px', marginBottom: '10px', textAlign: 'center' } }}
            navButtonsAlwaysInvisible={true}
            index={activeSlide}
            cycleNavigation={true}

          >

            {
              items.map((item, i) => <Item key={i} item={item} items={items}
                prevIndex={prevIndex} nextIndex={nextIndex}
              />)
            }
          </Carousel>

          <Carousel duration={400} animation='slide' autoPlay={false} navButtonsAlwaysInvisible >
            <Box className={classes.carouselnext}>
              <Box style={{ marginBottom: '20px' }}>
                {items[nextIndex].avatar}
              </Box>
              <Box>
                <Typography variant='body1'>{items[nextIndex].name}</Typography>
                <Typography variant='body2'>{items[nextIndex].designation}</Typography>
              </Box>
            </Box>
          </Carousel>

        </Box>

        <Box className={`${classes.navIcon} ${classes.prevButton}`}>
          <IconButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disabled={isNextButtonDisabled} onClick={handlenavigationNext}>
            <NavigateNextIcon fontSize='large' />
          </IconButton>
        </Box>

      </Box>
    </Box>


  )
}
export default CarouselContainer

function Item(props) {

  const classes = useStyles();

  return (

    <Box className={classes.carouselActive}>
      <Box style={{ marginBottom: '20px' }}>
        {props.item.avatar}
      </Box>
      <Box>
        <Typography style={{ fontWeight: 'bold' }} variant='body1'>{props.item.name}</Typography>
        <Typography variant='body2'>{props.item.designation}</Typography>
      </Box>
    </Box>
  )
}


