import React from "react";
import {Box} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Footer from "../footer/footer";
import Home from "../Main/HomeContainer/Home";
import AboutContainer from "../Main/AboutContainer/AboutContainer";
import ValueContainer from "../Main/ValueContainer/ValueContainer";
import App from "../nav/nav";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      margin: "0px",
      backgroundColor: '#ffff',
    },
  },
  layoutcontainer: {
    display: "flex",
    flexDirection: "column",
  },
  BoxContainer_class: {
    marginBottom: '20px',
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.layoutcontainer} style={{}} >
      <App />
      <Box className={classes.BoxContainer_class} >
        <Home />
        <AboutContainer />
        <ValueContainer />
      </Box>
      <Footer />
    </div>
  );
};

export default Layout
