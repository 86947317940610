import { Typography, Box, } from '@material-ui/core'
import React from 'react'
import { Element } from 'react-scroll'
import Valueframes from './Valueframes'
import { makeStyles, } from '@material-ui/core/styles'
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CarouselContainer from './Carouselcomponent'
import dataAnalytics from '../../../images/VectorsDataanalytics3.jpg'
import predictiveAnalytics from '../../../images/VectorsDataanalytics2.jpg'
import dataVisulation from '../../../images/Vectorsdatavisulation.jpg'
import AIimage from '../../../images/VectorsAIimage-2.jpg'
import DataSecurity from '../../../images/Vectorsdatasecurity3.jpg'
import dataConsulting from '../../../images/VectorsDataconsulting.jpg'


const useStyles = makeStyles((theme) => ({
    valueframeElement: {

        display: 'flex',
        flexDirection: 'column',
        // height:'100vh',
        marginBottom: '60px',

    },
    valueframeText: {
        textAlign: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        padding: '20px',
        marginBottom: '20px',
        "@media (max-width: 768px)": {

        }
    },
    valueframes: {
        display: 'flex',
        marginLeft: '60px',
        marginRight: '60px',
        marginBottom: '10px',
        justifyContent: 'space-evenly',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
            marginLeft: '5px',
            marginRight: '5px',
        },
        "@media (max-width: 1020px)": {
            flexDirection: 'column',
            marginLeft: '5px',
            marginRight: '5px',
        }
    },
    valueInfos: {

        display: 'flex',
        marginTop: '36px',
        marginLeft: '60px',
        marginRight: '60px',
        "@media (max-width: 768px)": {
            flexDirection: 'column',
            marginRight: '5px',
            marginLeft: '5px',

        }
    },
    infos: {
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: '5px',
        boxShadow: '0px 0 5px rgba(1, 41, 112, 0.20)',
        background: 'rgb(240, 240, 240)',
        marginRight: '20px',
        marginLeft: '20px',
        marginBottom: '20px !important',
        marginTop: '20px',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',

    },
    infosSymbol: {
        flex: 0.3,
        textAlign: 'center',
        marginBottom: '10px',

    },
    infosContent: {
        flex: 0.7,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    }
}))

const ValueContainer = () => {
    const classes = useStyles();

    const framedata = [
        {
            logo: dataAnalytics,
            tittle: 'Data Analytics and Insights',
            body: 'We employ advanced analytics techniques to extract valuable insights from complex data sets, enabling organizations to make informed decisions and optimize their operations'
        },
        {
            logo: predictiveAnalytics,
            tittle: 'Predictive Analytics',
            body: 'Our predictive modeling capabilities help businesses forecast future outcomes and trends, enabling proactive decision-making and improved planning'
        },
        {
            logo: dataVisulation,
            tittle: 'Data Visualization',
            body: 'We create intuitive and interactive visualizations that enable clients to explore and understand their data, uncover patterns, and communicate insights effectively'
        },
        {
            logo: AIimage,
            tittle: 'Machine Learning and AI',
            body: 'Leveraging the power of machine learning and artificial intelligence, we develop intelligent systems that automate processes, enhance efficiency, and enable predictive capabilities'
        },
        {
            logo: DataSecurity,
            tittle: 'Data Governance and Security',
            body: 'We prioritize data integrity, privacy, and security, implementing robust measures to ensure compliance with regulations and protect sensitive information'
        },
        {
            logo: dataConsulting,
            tittle: 'Consulting and Advisory Services',
            body: 'Our team of experts provides strategic guidance, helping organizations develop data-driven strategies and roadmap their analytics journey'
        }
    ]

    const firstThree = framedata.slice(0, 3);
    const nextThree = framedata.slice(3, 6);

    const valueInfos = [
        {
            symbol: <SentimentSatisfiedOutlinedIcon style={{ color: '#4154f1' }} fontSize='large' />,
            numbers: '8+',
            text: 'Happy Clients'
        },
        {
            symbol: <DescriptionOutlinedIcon style={{ color: '#ee6c20' }} fontSize='large' />,
            numbers: '15+',
            text: 'Projects'
        },
        {
            symbol: <BusinessOutlinedIcon style={{ color: '#15be56' }} fontSize='large' />,
            numbers: '2',
            text: 'Branches'
        },
        {
            symbol: <PeopleAltOutlinedIcon style={{ color: '#bb0852' }} fontSize='large' />,
            numbers: '30+',
            text: 'Hard Workers'
        }
    ]

    return (
        <Element className={classes.valueframeElement} name='Services' style={{}} >
            <Box className={classes.valueframeText}>
                <Typography style={{ marginBottom: '10px', textAlign: 'center' }} variant='h2'>Our Services</Typography>
                <Typography variant='body1'>
                    At Datinfi, we are committed to delivering exceptional service, fostering long-term partnerships, and driving measurable business impact through data analytics. Our passion for innovation and continuous improvement enables us to stay ahead of the curve and provide our clients with the competitive edge they need in today's data-driven world.
                </Typography>
            </Box>
            <Box className={classes.valueframes}>
                {firstThree.map((item, key) => (
                    <Valueframes logo={item.logo} tittle={item.tittle} body={item.body} key={key} ></Valueframes>
                ))}
            </Box>
            <Box className={classes.valueframes}>
                {nextThree.map((item, key) => (
                    <Valueframes logo={item.logo} tittle={item.tittle} body={item.body} key={key} ></Valueframes>
                ))}
            </Box>
            <Box className={classes.valueInfos}>
                {valueInfos.map((item, key) => (
                    <Box style={{ flex: 1 }} key={key}>
                        <Box className={classes.infos} key={key}>
                            <Box className={classes.infosSymbol}> {item.symbol}</Box>
                            <Box className={classes.infosContent}>
                                <Typography variant='body1'>
                                    {item.numbers}
                                </Typography>
                                <Typography variant='body1'>
                                    {item.text}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <CarouselContainer />
        </Element>
    )
}

export default ValueContainer