import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  Facebook,
  Twitter,
  LinkedIn,
  YouTube,
  Instagram,
} from "@material-ui/icons";
import { Element } from "react-scroll";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    // position: 'fixed',
    bottom: 0,
    width: "100%",
  },
  root: {
    backgroundColor: "rgb(247, 247, 247)",
    color: "#000",
    padding: "48px 48px 30px 48px",
  },
  socialMedia: {
    marginTop: "30px",
  },
  socialIcons: {
    display: "flex",
    "& > *": {
      // margin: theme.spacing(0, 1),
      color: "#fff",
    },
    "@media (max-width: 1000px)": {
      // margin:theme.spacing(0,0.4)
    },
  },
  copyrights: {
    marginTop: "60px",
  },
  footerbutton: {
    backgroundColor: "#03a1a1e0",
    color: "white",
    fontSize: "0.875rem",
    letterSpacing: "0.02857em",
    "&:hover": {
      backgroundColor: "#03a1a1e0",
      color: "white",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Element className={classes.footerContainer} name="Contact">
      <div>
        <footer className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Contact Us
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: 550 }}>Address:</span> Chandra
                  Texitle Mills Compound, GRG Gen Nxt Incubator, 1708,
                </Typography>
                <Typography variant="body2">
                  {" "}
                  Avinashi Rd, opp. Govt Polytechnic, Civil Aerodrome Post,{" "}
                </Typography>
                <Typography variant="body2">
                  {" "}
                  Coimbatore, Tamilnadu- 641014{" "}
                </Typography>
                <Divider
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    width: "60%",
                  }}
                />
                <Typography variant="body2" gutterBottom>
                  Wersel Workdesk,Level39, One Canada Square, <br />
                  Canary Wharf, London E14 5AB
                </Typography>
                <Divider
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    width: "60%",
                  }}
                />
                <Typography variant="body2">
                  <span style={{ fontWeight: 550 }}>Open hours:</span> 09:30 am
                  - 07:00 pm
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <span style={{ fontWeight: 550 }}>Week off:</span>{" "}
                  {"Saturday & Sunday"}
                </Typography>

                <Typography variant="body2">
                  <span style={{ fontWeight: 550 }}>Email:</span>{" "}
                  careers@datinfi.com
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: 550 }}>Phone:</span> (123) 456-7890
                </Typography>
              </Grid>
              <Grid item className={classes.socialMedia}>
                <Typography variant="h6" gutterBottom>
                  Follow Us
                </Typography>
                <div className={classes.socialIcons}>
                  <IconButton>
                    <a
                      href="https://www.facebook.com/datinfi?mibextid=kFxxJD"
                      aria-label="Facebook"
                    >
                      <Facebook />
                    </a>
                  </IconButton>

                  <IconButton disabled aria-label="Twitter">
                    <Twitter
                      style={{ color: /*'#00acee'*/ "rgb(210, 210, 210)" }}
                    />
                  </IconButton>
                  <IconButton aria-label="LinkedIn">
                    <a
                      href="https://www.linkedin.com/company/datainfi/"
                      aria-label="Facebook"
                    >
                      <LinkedIn />
                    </a>
                  </IconButton>
                  <IconButton disabled aria-label="Youtube">
                    <YouTube
                      style={{ color: /*'#b2071d'*/ "rgb(210, 210, 210)" }}
                    />
                  </IconButton>
                  <IconButton disabled aria-label="Instgram">
                    <Instagram
                      style={{ color: /*'#E4405F'*/ "rgb(210, 210, 210)" }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Contact Form
              </Typography>
              <form>
                <TextField disabled label="Name" fullWidth margin="normal" />
                <TextField disabled label="Email" fullWidth margin="normal" />
                <TextField
                  disabled
                  label="Message"
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={4}
                />
                <Button
                  className={classes.footerbutton}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <Typography
                    style={{ textTransform: "none" }}
                    variant="button"
                  >
                    Send
                  </Typography>
                </Button>
              </form>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            align="center"
            color="inherit"
            gutterBottom
            className={classes.copyrights}
          >
            ©2023. Datinfi Pvt. Ltd. All rights reserved.
          </Typography>
        </footer>
      </div>
    </Element>
  );
};

export default Footer;
