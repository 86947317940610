import React from 'react'
import { Element } from 'react-scroll'
import HomeContent from './HomeContent'
import { makeStyles } from '@material-ui/core/styles';
import contentimage2 from '../../../images/glowing-blue-sphere-held-by-human-hand-generated-by-ai.jpg'




import { Box } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  homecontainer: {
    marginTop: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    height: "100vh",
    boxShadow: 'inset 200',
    display: 'flex',
    backgroundImage: `url(${contentimage2})`,
    backgroundSize: '100% 125%',
    backgroundRepeat: 'no-repeat',
    objectFit: 'contain',
    "@media (max-width: 768px)": {
      backgroundSize: '100% 100%',
    },

  },
  homecontent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',


  },


}))

const Home = () => {

  const classes = useStyles();
  return (

    <Element name='Home' className={classes.homecontainer}>
      <Box className={classes.homecontent}>
        <HomeContent />
      </Box>
    </Element>

  )
}

export default Home