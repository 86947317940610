import React from 'react'
import { Box, Typography, } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
    valueframes: {
        height: '450px',
        maxWidth: '400px',
        marginRight: '20px',
        marginLeft: '20px',
        marginBottom: '30px',
        marginTop: '30px',
        paddingBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'transform 200ms ease-in-out',
        borderRadius: '5px',
        boxShadow: '0px 0 5px rgba(1, 41, 112, 0.20)',
        '&:hover': {
            // transform: 'scale(0.9)',
            cursor: 'pointer',
            boxShadow: '0px 0 5px rgba(1, 41, 112, 0.28)',
        },
        "@media (max-width: 1200px)": {
            maxWidth: '100%',
        }


    },
    frameimage: {
        flex: 0.7,
        display: 'flex',
        justifyContent: 'center',


    },
    frametext: {
        flex: 0.3,
        textAlign: 'center',
        paddingRight: '30px',
        paddingLeft: '30px',
    },
    imagesfluid: {
        maxWidth: '250px',
        maxHeight: '250px',
        paddingTop: '30px',
        "@media (max-width: 768px)": {
            width: '50',
        },
    },
    Cardvalues: {

    }
}))


function Valueframes({ tittle, body, logo }) {
    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.valueframes} >
                <Box className={classes.frameimage} >
                    <img className={classes.imagesfluid} src={logo} alt='image not responding' ></img>
                </Box>
                <Box className={classes.frametext}>
                    <Typography style={{ fontWeight: 'bold' }} variant='body1'>{tittle}</Typography>
                    <Typography variant='body2'>{body}</Typography>
                </Box>
            </Box>
        </Box>

    )
}

export default Valueframes