
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';



const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f44336',
    },
    appbar: {
      main: 'rgb(241 240 240)',
      greenGradient: 'linear-gradient(to right, #003366 0%, #009999 100%);',
      greenLight: '#03a1a1e0', //green with high transparent ,
      greenDark: '#03a1a1f8', // green with low transparent,
    },
    footer: {
      main: 'rgb(247, 247, 247)'
    }
  },
  typography: {
    fontFamily: 'Roboto, Poppins,sans-serif',
    h1: {
      fontSize: 40,
      "@media (max-width: 768px)": {
        fontSize: 25,
      },
      "@media (max-width: 600px)": {
        fontSize: 20,
      }

    },
    h2: {
      fontSize: 35,
      "@media (max-width: 768px)": {
        fontSize: 25,
      },"@media (max-width:426px)": {
        fontSize: 20,
      },
      "@media (max-width: 320px)": {
        fontSize: 18,
      }
  
    },
    h3: {
      fontSize: 25,
      "@media (max-width: 600px)": {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 20,
      "@media (max-width: 600px)": {
        fontSize: 15,
      },
    },
    body1: {
      fontSize: 15,
      fontWeight: '500',
      "@media (max-width: 600px)": {
        fontSize: 15,
      }
    },
    button: {
      fontSize: 15,
      color: 'black',
      "@media (max-width: 600px)": {
        fontSize: 15,
      },

    }
  },

});

export default { theme: responsiveFontSizes(lightTheme) }
