import React from "react";
import {
  makeStyles,
} from "@material-ui/styles";
import Layout from "./components/layout/index";

const useStyles = makeStyles((theme) => ({
  
}))

const App = () => {

  const classes = useStyles();

  return (
    <div>
      <Layout/>
    </div>
  );
};

export default App
