import React from 'react'
import { Typography, Box, } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({

  HomeContent__Box_conatiner: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

  },
  homecontent__text: {
    color: 'white',
  },
  contentbox: {
    opacity:0.8,
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight:'0px',
    marginTop:'50px',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    flex: 1,
    paddingTop: '10px',
    paddingRight:'0px',
    paddingLeft:'0px',
    "@media (max-width: 600px)": {
      textAlign: 'center',
      alignItems: 'flex-end',
      marginBottom: '10px',  
      paddingTop: '30px',
      marginLeft:'20px',
      marginRight:'20px',
    },
  },
  'MuiButton-outlined': {
    backgroundColor: '#03a1a1e0',
    color: 'white',
    border: 'none',
    '&:hover': {
      backgroundColor: '#03a1a1e0',
      color: 'white',
    },
  },

}))


function HomeContent() {
  const classes = useStyles();
  return (
    <Box className={classes.HomeContent__Box_conatiner} >
      <Box className={classes.contentbox}>
        <Box variant='h2' className={classes.homecontent__text}>
          <Typography variant='h2'>Empower Your Business with AI-Driven Data Analysis </Typography>
          {/* <Button className={classes['MuiButton-outlined']} variant='outlined'>
            <Typography variant='body1' style={{textTransform:'none'}}>Get Started</Typography>
          </Button> */}
        </Box>
      </Box>
    </Box>
  )
}

export default HomeContent