import React from 'react'
import { Element } from 'react-scroll'
import { Box, } from '@material-ui/core'
import VectorTeamImage from '../../../images/VectorTeam.jpg'
import AboutContent from './AboutContent'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    About__container__Element__container: {
        display: "flex",
        // height:'100vh',
        marginLeft: '20px',
        marginRight: '20px',
        padding: '30px',
        flexDirection: 'row-reverse',
        marginBottom: '20px',
        borderRadius: 6,
        "@media (max-width: 600px)": {

        },
        "@media (max-width: 900px)": {
            height: 'auto',
            flexDirection: 'column-reverse'
        },
    },

    aboutcontainerImage: {
        flex: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerImgcomp: {
        maxWidth: '500px', maxHeight: '600px', objectFit: 'contain',
        "@media (max-width: 600px)": {
            maxWidth: '300px',
            maxHeight: '300px',
        },
        "@media (max-width: 400px)": {
            maxWidth: '280px',
            maxHeight: '400px',
        },
    },

    aboutContainerText: {
        flex: 0.5,
        display: "flex",
    }
}))

function AboutContainer() {
    const classes = useStyles();
    return (
        <Element name="About" className={classes.About__container__Element__container} >
            <Box className={classes.aboutcontainerImage}>
                <img className={classes.containerImgcomp} src={VectorTeamImage} alt='loading!..' ></img>
            </Box>
            <Box className={classes.aboutContainerText}>
                <AboutContent/>
            </Box>
        </Element>
    )
}

export default AboutContainer